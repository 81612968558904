import React ,  {useState} from 'react';
import './Contact.css';
import { EnvelopeAt, Telephone ,Linkedin ,Github  } from 'react-bootstrap-icons';
import * as Yup from 'yup';
import {useFormik, validateYupSchema } from 'formik';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify'

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .required('Required'),
  subject: Yup.string()
    .min(2, 'Too Short!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  message: Yup.string()
    .min(25, 'should be at least 25 character')
    .required('Required'),
});


const sendEmail = async (values , id ) => {  
  
 try {
   const res = await fetch(
    
     'https://alaaeddinezakraoui.vercel.app/sendmail',
    //  'http://localhost:5000/sendmail',
    
    
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(values)
    });

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }
  toast.update(id, { render: "Email sent successfully!", closeOnClick: true, draggable: true, type: "success", isLoading: false, theme: 'colored' });
    return res.json(); // Assuming your server sends a JSON response
  } catch (error) {
    toast.update(id, { render: "something went wrong :(", closeOnClick: true, draggable: true, type: "error", isLoading: false , theme:'colored' });

  }

};



const onSubmit = async ( values, actions ) => {


  const id = toast.loading("Please wait...")

  const myPromise = sendEmail(values , id);
  actions.resetForm()


    //  await new Promise((resolve) => setTimeout(() => {
    //   console.log("done")
    //   resolve();
    // }, 3000));

}




const Contact = () =>{



  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isValid , resetForm } = useFormik({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: ''
    },
    validationSchema: SignupSchema,
    onSubmit 

  })




return(

  <section data-aos="flip-right"   className="ftco-section contact-section ftco-no-pb" id="contact-section">
  <div className="container">
      <div data-aos="fade-right"  className="row justify-content-center mb-5 pb-3">
      <div className="col-md-7 heading-section text-center ftco-animate fadeInUp ftco-animated">
        <h1 className="big big-2">Contact</h1>
        <h2 className="mb-4">Contact Me</h2>
          <p> Feel free to reach out if you have any inquiries . I'm always open to new opportunities and interesting projects. Your feedback is valuable to me, so don't hesitate to drop a message.</p>
      </div>
    </div>
      <div data-aos="fade-left" className="row d-flex contact-info mb-5">
        <div data-aos="fade-up-right" className="col-md-6 col-lg-3 d-flex ftco-animate fadeInUp ftco-animated">
        <div className="align-self-stretch box p-4 text-center">
            <div className="icon d-flex align-items-center justify-content-center">
              {/* <span className="bi bi-whatsapp " /> */}
              <span>  <Telephone /></span>
             
          </div>
          <h3 className="mb-4">Phone Number</h3>
            <a style={{ color:'white'}} href="tel://+212628969000">+212 628969000</a>
          <p></p>
        </div>
      </div>
        <div data-aos="flip-up" className="col-md-6 col-lg-3 d-flex ftco-animate fadeInUp ftco-animated">
        <div className="align-self-stretch box p-4 text-center">
          <div className="icon d-flex align-items-center justify-content-center">
              <span>  <EnvelopeAt /></span>
              
          </div>
          <h3 className="mb-4">Contact Number</h3>
            <p><a href="mailto:piget3@gmail.com">piget3@gmail.com</a></p>
        </div>
      </div>
        <div data-aos="flip-down" className="col-md-6 col-lg-3 d-flex ftco-animate fadeInUp ftco-animated">
        <div className="align-self-stretch box p-4 text-center">
          <div className="icon d-flex align-items-center justify-content-center">
              <span>  <Linkedin /></span>
          </div>
          <h3 className="mb-4">Linkedin</h3>
            <p><a target="_blank" href="https://www.linkedin.com/in/alaa-eddine-zakraoui/">Alaa Eddine Zakraoui</a></p>
        </div>
      </div>
        <div data-aos="fade-down-left"  className="col-md-6 col-lg-3 d-flex ftco-animate fadeInUp ftco-animated">
        <div className="align-self-stretch box p-4 text-center">
          <div className="icon d-flex align-items-center justify-content-center">
              <span>  <Github /></span>
          </div>
          <h3 className="mb-4">Website</h3>
            <p><a target="_blank" href="https://github.com/OtakuZzz">OtakuZzz</a></p>
        </div>
      </div>
    </div>
      <div data-aos="fade-right"  className="row no-gutters block-9">
        <div className="col-12 col-md-6 d-flex">
          <div className="img" />
        </div>
        <div data-aos="flip-right"  className="col-12 col-md-6 d-flex">
          <form onSubmit={isValid ? handleSubmit : (e) => {
            e.preventDefault();
            toast.warn('you need to complete the form !! ', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })} } className="bg-light p-4 pe-5 p-md-5 contact-form">
            <div className="form-group ">
              <input value={values.name} onChange={handleChange} onBlur={handleBlur} name="name" type="text" className={"form-control " + (errors.name && touched.name ? "err" : "")} placeholder="Your Name" />
              {errors.name && touched.name ? <div className="error-message">{errors.name}</div> : null}
            </div>
            <div className="form-group">
              <input value={values.email} onChange={handleChange} onBlur={handleBlur} name="email" type="text" className={"form-control " + (errors.email && touched.email ? "err" : "")} placeholder="Your Email" />
              {errors.email && touched.email ? <div className="error-message">{errors.email}</div> : null}
            </div>
            <div className="form-group">
              <input value={values.subject} onChange={handleChange} onBlur={handleBlur} name="subject" type="text" className={"form-control " + (errors.subject && touched.subject ? "err" : "")} placeholder="Subject" />
              {errors.subject && touched.subject ? <div className="error-message">{errors.subject}</div> : null}
            </div>

            <div  className="form-group">
              <textarea value={values.message}
               onChange={handleChange}
                onBlur={handleBlur}
                 name="message"  cols={30} rows={7} className={"form-control " + (errors.message && touched.message ? "err" : "")} placeholder="Message"  />
              {errors.message && touched.message ? <div className="error-message">{errors.message}</div> : null}
            </div>

            <div className="form-group">
              <input  type="submit" defaultValue="Send Message" className="btn btn-primary py-3 px-5" />
            </div>
          </form>


        </div>

      </div>
    </div>

          {/* <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              // same shape as initial values
              console.log(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <Field name="firstName" />
                {errors.firstName && touched.firstName ? (
                  <div>{errors.firstName}</div>
                ) : null}
                <Field name="lastName" />
                {errors.lastName && touched.lastName ? (
                  <div>{errors.lastName}</div>
                ) : null}
                <Field name="email" type="email" />
                {errors.email && touched.email ? <div>{errors.email}</div> : null}
                <button type="submit">Submit</button>
              </Form>
            )}
          </Formik> */}


 
</section>
);}


export default Contact;

import './header.css'

const Header = () => (


   <nav className="navbar" id="ftco-navbar">
      <div className="container" >
         <a className="navbar-brand" href="index.html">ALAA EDDINE</a>
         {/* <button className="navbar-toggler js-fh5co-nav-toggle fh5co-nav-toggle collapsed" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="oi oi-menu"></span> Menu
         </button> */}

         <div className="navbar" id="ftco-nav" >
            <ul className="navbar-nav">
               <li className="nav-item"><a href="#home-section" ><span>Home</span></a></li>
               <li className="nav-item"><a href="#about-section" ><span>About</span></a></li>
               <li className="nav-item"><a href="#resume-section" ><span>Resume</span></a></li>
               <li className="nav-item"><a href="#skills-section" ><span>Skills</span></a></li>
               <li className="nav-item"><a href="#contact-section" ><span>Contact</span></a></li>
            </ul>
         </div>
      </div>
   </nav>



);

export default Header;

import React, { useState, useEffect } from "react";
import "./Slider.css";

const slides=[
  {
    title:"Data Scientist",
    content:"Welcome to Alaa Eddine's Corner,",
    img:"../../asset/images/test2.jpg"
  },
  {
    title:"Full Stack Developer",
    content: " ENSAM University Bootcamp Graduate | JobinTech ",
    img: "../../asset/images/Jobintech.jpg"
  },
  {
    title:"Welcome to My Portfolio",
    content: "Always Improving!",
    img: "../../asset/images/hello-world.png"
  }
]


const Slider = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isSliding, setIsSliding] = useState(false);

  useEffect(() => {
    const slidingAT = 1300;

    function handleControlClick(isRight) {
      if (isSliding) return;

      setIsSliding(true);

      let newIndex = isRight ? (activeSlide + 1) % 3 : ((activeSlide - 1) % 3 + 3) % 3;
      setActiveSlide(newIndex);

      setTimeout(() => {
        setIsSliding(false);
      }, slidingAT * 0.75);
    }

    const controls = document.querySelectorAll(".slider__control");
    controls.forEach((control) => {
      control.addEventListener("click", () => {
        const isRight = control.classList.contains("m--right");
        handleControlClick(isRight);
      });
    });

    return () => {
      controls.forEach((control) => {
        control.removeEventListener("click", () => {
          const isRight = control.classList.contains("m--right");
          handleControlClick(isRight);
        });
      });
    };
  }, [activeSlide, isSliding]);

  return (
    <div className="slider">
      <div className="slider__slides">
        {slides.map((slide,index) => (
          <div
            style={{ backgroundImage: `url(${slide.img})` }}
            key={index}
            className={`slide ${index == activeSlide ? "s--active" : ""} ${index == ((activeSlide -1)%3+3)%3 ? "s--prev" : ""}   `}
          >
            <div className="slide__inner">
              <div className="slide__content">
                <h2 className="slide__heading"> {slide.title}</h2>
                <p className="slide__text">{slide.content}</p>
              </div>
            </div>
          </div>
        ))
        
        
        }
      </div>
      <div className="slider__control">
        <div className="slider__control-line" />
        <div className="slider__control-line" />
      </div>
      <div className="slider__control slider__control--right m--right">
        <div className="slider__control-line" />
        <div className="slider__control-line" />
      </div>
    </div>
  );
};

export default Slider;

import 'aos/dist/aos.css';
import '../../node_modules/font-awesome/css/font-awesome.min.css';
import Header from './Header/Header'
import Slider from './Slider/Slider'
import About from './About/About'
import Resume from './resume/resume'
import Contact from './Contact/Contact'
import MySkills from './MySkills/MySkills'
import Experience from './Experience/Experience';
import Footer from './footer/footer';
import 'animate.css'

import {ToastContainer } from 'react-toastify'



const App = ()=>{

    return(
        <div>
            
            <Header/>
            <Slider/>
            <About />
            <Resume />
            <MySkills />
            {/* <Experience/> */}
            <Contact />
            <Footer/>
            <ToastContainer />

        </div>
        
    )
}


export default App; 
import React from 'react';
import './About.css';
import { useEffect } from 'react';
import AOS from "aos";
import CV from '../../asset/AlaaEDDine CV_1.pdf'

const About = () =>{
  
  useEffect(() => {
    AOS.init();
  })
  
  
  return (

    <section data-aos="fade-up" className="ftco-about img ftco-section "  >
    <div className="container">
        <div className="row d-flex justify-content-evenly">


          <div data-aos="fade-right" className="col-xs-12 col-md-6 col-lg-5 d-flex ">
            <div id="about-section" className="img-about img d-flex  overlay">
            <div id="me" className="img d-flex align-self-stretch" style={{}}>
            </div>
          </div>
        </div>



          <div data-aos="fade-left" className="col-xs-12 col-md-6  col-lg-5 pb-5">
          <div className="row justify-content-start pb-3">
            <div className="col-md-12 heading-section ftco-animate fadeInUp ftco-animated">
              <h1 className="big">About</h1>
              <h2 className="mb-4 ">About Me</h2>
                <p style={{ 'color': 'white', 'padding': '10px' }}>
                  I am currently a second-year student in Data Science and Engineering, deeply passionate about my academic journey. My studies have equipped me with strong skills in data analysis, machine learning, and software development. Simultaneously, I thrive in the world of web development. I am now seeking an end-of-studies internship opportunity to apply my knowledge and continue growing in these exciting field</p>
              <ul className="about-info mt-4 px-md-0 px-2">
                <li className="d-flex"><span >Name:</span> <span>Alaa EDDine Zakraoui</span></li>
                <li className="d-flex"><span>Date of birth:</span> <span>April 27, 2000</span></li>
                <li className="d-flex"><span>Address:</span> <span>Av-El Majd CYM Rabat
                  </span></li>
                <li className="d-flex"><span>Email:</span> <span>pigeta3@gmail.com</span></li>
                <li className="d-flex"><span>Phone: </span> <span>+212 6 28 96 9000</span></li>
              </ul>
            </div>
          </div>
          <div className="counter-wrap ftco-animate d-flex fadeInUp ftco-animated">
            <div className="text">
              <p className="mb-4">
                {/* <span className="number" data-number={120}> 120 </span> */}
                {/* <span>Here s my CV</span> */}
              </p>
                <p><a href={CV} className="btn btn-primary py-3 px-3" download>Download CV</a></p>
            </div>
          </div>
        </div>



      </div>
    </div>
  </section>


)};



export default About;

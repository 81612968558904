import React from 'react';
import './Footer.css';


const Footer = () =>{


  
  return (
<section>
      <footer data-aos="fade-up" className="ftco-footer ftco-section">
    <div className="container">
          <div data-aos="flip-right" className="row mb-5">
            <div data-aos="fade-up-right" className="col-md">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">About</h2>
                <p>
                  I am currently a second-year student in Data Science and Engineering, deeply passionate about my academic journey. My studies have equipped me with strong skills in data analysis, machine learning, and software development. Simultaneously, I thrive in the world of web development. I am now seeking an end-of-studies internship opportunity to apply my knowledge and continue growing in these exciting field</p>

          </div>
        </div>
            <div data-aos="fade-right"  className="col-md">
          <div className="ftco-footer-widget mb-4 ml-md-4">
            <h2 className="ftco-heading-2">Links</h2>
            <ul className="list-unstyled">
              <li><a href="#"><span className="icon-long-arrow-right mr-2" />Home</a></li>
              <li><a href="#about-section"><span className="icon-long-arrow-right mr-2" />About</a></li>
              <li><a href="#sevice-section"><span className="icon-long-arrow-right mr-2" />Services</a></li>
              <li><a href="#project-section"><span className="icon-long-arrow-right mr-2" />Projects</a></li>
              <li><a href="#contact-section"><span className="icon-long-arrow-right mr-2" />Contact</a></li>
            </ul>
          </div>
        </div>
            <div data-aos="fade-left"  className="col-md">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">Services</h2>
            <ul className="list-unstyled">
              <li><a href="#contact-section"><span className="icon-long-arrow-right mr-2" />Web Development</a></li>
                  <li><a href="#contact-section"><span className="icon-long-arrow-right mr-2" />Data Engineering</a></li>
                  <li><a href="#contact-section"><span className="icon-long-arrow-right mr-2" />Data Science</a></li>
            </ul>
          </div>
        </div>
            <div data-aos="fade-down-left" className="col-md">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">Have a Questions?</h2>
            <div className="block-23 mb-3">
              <ul>
                <li><span className="icon icon-map-marker" /><span className="text">Rabat</span></li>
                <li><a href="#contact-section"><span className="icon icon-phone" /><span className="text">+212 6 28 96 90 00</span></a></li>
                <li><a href="#contact-section"><span className="icon icon-envelope" /><span className="text">pigeta3@gmail.com</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
          <div data-aos="fade-left" className="row">
        <div className="col-md-12 text-center">
          <h1>
             Thanks for visiting..
        </h1>
        </div>
      </div>
    </div>
  </footer>
</section>

);}



export default Footer;

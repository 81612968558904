import React from 'react';
import './MySkills.css';

const MySkills = () => {

  
  
  return (
    <section  data-aos="fade-up" className="ftco-section" id="skills-section">
  <div className="container">
    <div className="row justify-content-center pb-5">
      <div className="col-md-12 heading-section text-center ftco-animate fadeInUp ftco-animated">
        <h1 className="big big-2">Skills</h1>
        <h2 className="mb-4">My Skills</h2>
            <p>"Here's what I'm good at (and I'm continually picking up new things too)."</p>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6 animate-box">
        <div className="progress-wrap ftco-animate fadeInUp ftco-animated">
          <h3>React</h3>
          <div className="progress">
            <div className="progress-bar color-1" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100} style={{width: '90%'}}>
              <span>90%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 animate-box">
        <div className="progress-wrap ftco-animate fadeInUp ftco-animated">
          <h3>PHP</h3>
          <div className="progress">
            <div className="progress-bar color-2" role="progressbar" aria-valuenow={85} aria-valuemin={0} aria-valuemax={100} style={{width: '85%'}}>
              <span>85%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 animate-box">
        <div className="progress-wrap ftco-animate fadeInUp ftco-animated">
          <h3>HTML5 CSS3 JavaScript</h3>
          <div className="progress">
            <div className="progress-bar color-3" role="progressbar" aria-valuenow={95} aria-valuemin={0} aria-valuemax={100} style={{width: '95%'}}>
              <span>95%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 animate-box">
        <div className="progress-wrap ftco-animate fadeInUp ftco-animated">
          <h3>Machine Learning</h3>
          <div className="progress">
            <div className="progress-bar color-4" role="progressbar" aria-valuenow={80} aria-valuemin={0} aria-valuemax={100} style={{width: '80%'}}>
              <span>80%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 animate-box">
        <div className="progress-wrap ftco-animate fadeInUp ftco-animated">
          <h3>Java</h3>
          <div className="progress">
            <div className="progress-bar color-5" role="progressbar" aria-valuenow={80} aria-valuemin={0} aria-valuemax={100} style={{width: '80%'}}>
              <span>80%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 animate-box">
        <div className="progress-wrap ftco-animate fadeInUp ftco-animated">
          <h3>Networks</h3>
          <div className="progress">
            <div className="progress-bar color-6" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100} style={{width: '90%'}}>
              <span>90%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

);}



export default MySkills;

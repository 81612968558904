
import React from "react";
import "./resume.css";

export default function Resume() {


  return (
    <section id="resume-section" data-aos="zoom-in" >
      <div className="container" >
        <div  className="row justify-content-center pb-5">
    <div className="col-md-10 heading-section text-center ftco-animate fadeInUp ftco-animated">
            <h1  className="big big-2">Resume</h1>
            <h2   className="mb-4">Resume</h2>
            <p> This section serves as a comprehensive representation of my educational background, internships, and various projects, showcasing my diverse experiences and skills.</p>
    </div>
  </div>
  <div className="row">
          <div className="col-md-6" >
            <div data-aos="fade-up-right"  className="resume-wrap ftco-animate fadeInUp ftco-animated">
        <span className="date">2023</span>
        <h2>Full Stack php JobinTech </h2>
        <span className="position">ENSAM University</span>
              <p className="mt-4">During a rigorous 4-month bootcamp, I delved into a Full Stack PHP program at ENSAM University. This immersive experience honed my skills in PHP, HTML, CSS, JavaScript, React, Angular, and project management, all while fostering professional communication and various other valuable competencies.</p>
      </div>

            <div data-aos="fade-up-right" className="resume-wrap ftco-animate fadeInUp ftco-animated">
              <span className="date">2022</span>
              <h2>(PFE) Gaz Leak Security</h2>
              <span className="position">FSR</span>
              <p className="mt-4">Design and Implementation of a website 'Gaz Leak Security' for Factory Management and Gas Leak Detection (utilizing Arduino, Raspberry Pi, sensors, HTML, CSS, JavaScript, Python, SQL, MySQL, etc.)

                In this project, I undertook the development of the 'Gaz Leak Security' website, which serves as a comprehensive solution for managing factory operations and detecting gas leaks. The system is built using a range of technologies and components, including Arduino and Raspberry Pi for hardware integration, various sensors for gas leak detection, and a web-based user interface implemented using HTML, CSS, and JavaScript. Additionally, I used Python for scripting and SQL, including MySQL, for data storage and management. This project allowed me to apply my knowledge and skills in multiple domains, combining hardware and software to address critical safety and operational concerns in industrial settings."</p>
            </div>

                        <div data-aos="fade-up-right" className="resume-wrap ftco-animate fadeInUp ftco-animated">
              <span className="date">2023</span>
              <h2>Web Application Firewall using Machine Learning</h2>
              <span className="position">ENSAM</span>
              <p className="mt-4">During my internship, I worked on the design and development of a web application firewall aimed at detecting and preventing cyberattacks using machine learning techniques. This project involved the use of various tools and libraries, including Pandas, NumPy, Seaborn, Scikit-Learn, and Python. Through this experience, I gained hands-on expertise in applying machine learning to enhance web security, making the digital landscape more resilient against cyber threats</p>
            </div>




    </div>
    <div className="col-md-6">
            <div data-aos="fade-up-left"  className="resume-wrap ftco-animate fadeInUp ftco-animated">
        <span className="date">2022-2024</span>
        <h2>Master in Science and Data Engineering </h2>
        <span className="position">Science University Rabat (FSR)</span>
              <p className="mt-4">In my Master's education, I gained comprehensive knowledge and expertise in a wide array of subjects, including database administration, network security, advanced Java programming, embedded systems, data mining, deep learning, IoT (Internet of Things), industrial robotics, big data analytics, software engineering, and web service programming. These diverse disciplines have provided me with a robust foundation for addressing complex challenges in the field of computer science and technology.</p>
      </div>


            <div data-aos="fade-up-left" className="resume-wrap ftco-animate fadeInUp ftco-animated">
              <span className="date">2023</span>
              <h2>Networks CCNA</h2>
              <span className="position">Cisco</span>
              <p className="mt-4">CCNA stands for Cisco Certified Network Associate. It is a certification offered by Cisco, a leading networking technology company. CCNA certification is designed to validate an individual's knowledge and skills in networking, particularly in areas such as network fundamentals, routing and switching, security, wireless networking, and more. It is a widely recognized credential in the field of networking and can help professionals advance their careers in network administration and related roles. Cisco offers various levels of certification, with CCNA being an entry-level certification, often considered a foundational step in a networking career.</p>
            </div>
            <div data-aos="fade-up-right" className="resume-wrap ftco-animate fadeInUp ftco-animated">
              <span className="date">2018-2022</span>
              <h2>Licence in Computer Science (SMI) </h2>
              <span className="position">Science University Rabat (FSR)</span>
              <p className="mt-4">My Bachelor's degree in Computer Science (SMI) laid the essential groundwork in mathematics, physics, and computer science, with a focus on Java programming, computer networks, C programming, algorithms, web development, and various other fundamental subjects.</p>
            </div>

    </div>
  </div>

</div>
</section>

  );
}